import { deleteFolder, loadDocumentStorage } from 'helpers/DocumentHandlers';
import { Item, Prefixe } from 'models/general';
import { ReactComponent as FolderSvg } from 'assets/documents/svg/folder.svg';
import { ReactComponent as MenuIcon } from 'assets/documents/svg/menu.svg';
import React, {FormEvent, useEffect, useState} from 'react'
import { Dropdown, Menu, Popconfirm } from 'antd';
import { IconCut, IconDownload, IconEdit, IconTrash } from '@tabler/icons';
import { extensionsIcons } from 'Utils/Constants/DocumentContants';
import { Cancel } from '@material-ui/icons';
import { downloadFile, updateCutItemsStore } from 'services/documents/DocumentDetailsServices';
import dataStore from 'stores/dataStore';
import 'firebase/storage';
import firebase from 'firebase/app';



type Props = {
    key:number,
    prefix: Prefixe | Item,
    currentPath: string,
    setCurrentPath: React.Dispatch<React.SetStateAction<string>>,
    setParent: React.Dispatch<React.SetStateAction<string>>,
    params : any,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    type: 'folder' | 'file',
    setLoadingFileVersion: React.Dispatch<React.SetStateAction<boolean>>
    setOpenCutFile: React.Dispatch<React.SetStateAction<boolean>>,
    openCutFile:boolean,
    loadFileVersion(item: Item, setLoadingFileVersion: React.Dispatch<React.SetStateAction<boolean>>): Promise<void>
    confirmDeleteFile: (e: any, file: Item | Prefixe) => void
    setOpenUpdateFileVersion: (value: React.SetStateAction<boolean>) => void
    permission:string
    currentUser:any
    setOpenEditFolderName: React.Dispatch<React.SetStateAction<boolean>>,
    setEditingFolder: React.Dispatch<React.SetStateAction<Prefixe | Item | null>>,
}
const confirmDeleteFolder = (
    e: FormEvent<HTMLFormElement> | React.MouseEvent<HTMLElement, MouseEvent> | undefined,
    prefix: Prefixe | Item,
    currentPath: string,
    setCurrentPath: React.Dispatch<React.SetStateAction<string>>,
    setParent: React.Dispatch<React.SetStateAction<string>>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    params: any,
) => {
    deleteFolder(e,prefix,currentPath,setCurrentPath,setParent,setLoading,params)
};

const editFolderName = (
    prefix: Prefixe | Item,
    currentPath: string,
    setCurrentPath: React.Dispatch<React.SetStateAction<string>>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    params: any,
    setOpenEditFolderName: React.Dispatch<React.SetStateAction<boolean>>,
    setEditingFolder: React.Dispatch<React.SetStateAction<Prefixe | Item | null>>,
) => {
    setOpenEditFolderName(true);
    setEditingFolder(prefix);
};


const menuFolder = (
    prefix: Prefixe | Item,
    currentPath: string,
    setCurrentPath: React.Dispatch<React.SetStateAction<string>>,
    setParent: React.Dispatch<React.SetStateAction<string>>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    params: any,
    permission: string,
    currentUser: any,
    setOpenEditFolderName: React.Dispatch<React.SetStateAction<boolean>>,
    setEditingFolder: React.Dispatch<React.SetStateAction<Prefixe | Item | null>>,

) => (
    <Menu className="menu-container">
        <Menu.Item
            onClick={(e) => {
                e.domEvent.stopPropagation();
                editFolderName(prefix, currentPath, setCurrentPath, setLoading, params, setOpenEditFolderName, setEditingFolder);
            }}
            key="1"
            className="menu-item edit"
        >
            <IconEdit width={15} height={15} style={{ marginRight: "5px" }} />
            <span>Edit</span>
        </Menu.Item>

        {currentUser === "Global administrator" && (
            <Menu.Item
                onClick={(e) => {
                    e.domEvent.stopPropagation();
                }}
                key="2"
                className="menu-item delete"
            >
                <Popconfirm
                    overlayStyle={{ zIndex: 10003 }}
                    title="Are you sure to delete this folder?"
                    onConfirm={(e) =>
                        confirmDeleteFolder(
                            e,
                            prefix,
                            currentPath,
                            setCurrentPath,
                            setParent,
                            setLoading,
                            params
                        )
                    }
                >
                    <div onClick={(e) => e.stopPropagation()}>
                        <IconTrash width={15} height={15} style={{ marginRight: "5px" }} />
                        <span>Delete</span>
                    </div>
                </Popconfirm>
            </Menu.Item>
        )}
    </Menu>
);
type MenuProps = {
    item: Item | Prefixe,
    setLoadingFileVersion: React.Dispatch<React.SetStateAction<boolean>>,
    loadFileVersion(item: Item | Prefixe, setLoadingFileVersion: React.Dispatch<React.SetStateAction<boolean>>): Promise<void>,
    handleOpenUpdateFileVersion: () => void,
    setOpenCutFile: React.Dispatch<React.SetStateAction<boolean>>,
    openCutFile: boolean,
    confirmDeleteFile: (e: any, file: Item | Prefixe) => void,
    permission: string,
    currentUser: any
};

function DocumentMenuFile({ item, loadFileVersion, handleOpenUpdateFileVersion, setOpenCutFile, openCutFile, confirmDeleteFile, setLoadingFileVersion, permission, currentUser }: MenuProps) {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <Menu className="menu-container">
            <Menu.Item key="1" className="menu-item edit">
                <div onClick={(e) => { e.stopPropagation(); downloadFile(item) }}>
                    <IconDownload width={15} height={15} style={{ marginRight: '5px' }} />
                    <span>Download</span>
                </div>
            </Menu.Item>
            {permission === "Full access" || currentUser === 'Global administrator' ?
                <Menu.Item key="2" className="menu-item edit">
                    <div onClick={(e) => { e.stopPropagation(); loadFileVersion(item, setLoadingFileVersion); handleOpenUpdateFileVersion() }}>
                        <IconEdit width={15} height={15} style={{ marginRight: '5px' }} />
                        <span>Update version</span>
                    </div>
                </Menu.Item>
                : null}
            {permission === "Full access" || currentUser === 'Global administrator' ? (openCutFile ? (<Menu.Item key="3" className="menu-item Cut">
                <div onClick={(e) => { e.stopPropagation(); setOpenCutFile(false); updateCutItemsStore(item as any) }}>
                    <IconCut width={15} height={15} style={{ marginRight: '5px' }} />
                    <span>Cut</span>
                </div>
            </Menu.Item>) : (
                <Menu.Item key="4" className="menu-item cancel">
                    <div onClick={(e) => { e.stopPropagation(); setOpenCutFile(true); dataStore.resetItemsCutSelected() }}>
                        <Cancel width={15} height={15} style={{ marginRight: '5px' }} />
                        <span>Cancel Cut</span>
                    </div>
                </Menu.Item>)) : null}
            {currentUser === 'Global administrator' ? <Menu.Item onClick={(e) => { e.domEvent.stopPropagation() }} key="5" className="menu-item delete">
                <Popconfirm
                    overlayStyle={{ zIndex: 10003 }}
                    title="Are you sure to delete this file?"
                    onConfirm={(e) => confirmDeleteFile(e, item)}
                >
                    <div onClick={(e) => e.stopPropagation()}>
                        <IconTrash width={15} height={15} style={{ marginRight: '5px' }} />
                        <span>Delete</span>
                    </div>
                </Popconfirm>
            </Menu.Item> : null}
        </Menu>
    )
}
export default function DocumentsItem({
                                          prefix,
                                          setCurrentPath,
                                          setParent,
                                          params,
                                          setLoading,
                                          currentPath,
                                          type,
                                          setLoadingFileVersion,
                                          loadFileVersion,
                                          setOpenUpdateFileVersion,
                                          setOpenCutFile,
                                          openCutFile,
                                          confirmDeleteFile,
                                          permission,
                                          currentUser,
                                          setOpenEditFolderName,
                                          setEditingFolder,
                                      }: Props) {
    const [imageUrl, setImageUrl] = useState<string | null>(null);

    useEffect(() => {
        // Generate the correct URL for images
        const storageRef = firebase.storage().ref(prefix.fileUrl);

        storageRef
            .getDownloadURL()
            .then((url: string) => {
                setImageUrl(url);
            })
            .catch((error) => {
                console.error("Error getting image URL", error);
            });

        window.scrollTo({ top: 0, left: 0 });
    }, [prefix.fileUrl]);

    const getExtension = (fileName: string) => {
        return fileName.split('.').pop();
    };

    let extension = getExtension(prefix.name)?.toLocaleLowerCase();
    const imageExtensions = ["png", "jpeg", "jpg", "gif"];

    return (
        <div
            onClick={
                type === "folder"
                    ? (e) => {
                        e.stopPropagation();
                        loadDocumentStorage(
                            prefix.fileUrl,
                            setCurrentPath,
                            setParent,
                            params,
                            setLoading
                        );
                    }
                    : undefined
            }
            className="item-folder folder"
        >
            <div className="header-container">
                {type === "folder" && <FolderSvg className="folder-icon" />}
                {type === "file" && (
                    <>
                        {extension !== undefined ? (
                            imageExtensions.includes(extension) && imageUrl ? (
                                <img
                                    src={imageUrl}
                                    alt={prefix.name}
                                    style={{
                                        width: '100%',
                                        height: '200px',
                                        objectFit: 'cover',
                                        margin: '0 auto',
                                        display: 'block',
                                        borderRadius:'5px'
                                    }}
                                />
                            ) : (
                                extensionsIcons[extension as keyof typeof extensionsIcons]
                            )
                        ) : (
                            extensionsIcons["default"]
                        )}
                    </>
                )}
            </div>
            {/* Flexbox for file name and menu icon on the same line */}
            <div
                className="name-menu-container"
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: '5px',
                }}
            >
        <span
            className="item-title"
            style={{
                textAlign: 'left',
                fontSize: '14px',
                flex: 1,
            }}
        >
          {prefix.name}
        </span>
                <Dropdown
                    overlay={
                        type === "folder"
                            ? menuFolder(
                                prefix,
                                currentPath,
                                setCurrentPath,
                                setParent,
                                setLoading,
                                params,
                                permission,
                                currentUser,
                                setOpenEditFolderName,
                                setEditingFolder
                            )
                            : DocumentMenuFile({
                                item: prefix,
                                loadFileVersion,
                                handleOpenUpdateFileVersion: () =>
                                    setOpenUpdateFileVersion(true),
                                setOpenCutFile,
                                openCutFile,
                                confirmDeleteFile,
                                setLoadingFileVersion,
                                permission,
                                currentUser,
                            })
                    }
                    trigger={["hover"]}
                >
                    {type === "folder" && currentUser !== "Global administrator" ? (
                        <></>
                    ) : (
                        <MenuIcon
                            className="menu-icon"
                            onClick={(e) => e.stopPropagation()}
                            style={{
                                width: '16px',
                                height: '16px',
                                marginLeft: '10px',
                            }}
                        />
                    )}
                </Dropdown>
            </div>
        </div>
    );
}